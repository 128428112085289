<template>
  <div class="row m-0 px-lg-4">
    <div class="col-12 px-lg-5">
      <div class="row m-0 justify-content-center">
        <!--PAGE-->
        <div class="col-xl-5 col-lg-6 col-12 p-0 mt-lg-5" v-if="!ready">
          loading...
        </div>
        <div class="col-xl-5 col-lg-6 col-12 p-0 mt-lg-5" v-else>
          <div class="row m-0 text-center justify-content-center">
            <div class="col-12 my-4 text-center">
              <img :src="imgLink" class="profile_pic" v-if="imgLink">
              <img src="../assets/images/avatar.png" class="profile_pic" v-else>
            </div>
            <div class="col-12 mb-4 text-center font2p color_white regular">
              {{name}}
            </div>
            <div class="col-12 mb-4 font3 color_white regular">
              {{email}}
            </div>
            <div class="col-12 mb-4 font3 color_white regular" v-if="travelPlanner.signature.telephone">
              {{travelPlanner.signature.telephone}}
            </div>
            <div class="col-12 mb-4 font3 color_white regular" v-if="travelPlanner.signature.mobile">
              {{travelPlanner.signature.mobile}} (mobile) <a :href="whatsappLink"><waicon></waicon></a>
            </div>
            <div class="col-12 mb-4 font3 color_white regular" v-if="travelPlanner.signature.skype">
              {{travelPlanner.signature.skype}}
            </div>
            <!-- <div class="col-12 mb-4">
              +27 11 880 9992
            </div> -->
            <!-- <div class="col-xl-7 col-lg-9 col-7 mb-4">
              <button class="btn button-dark p-3 w-100 font3 color_white semibold">Send Message</button>
            </div> -->
            <div class="col-xl-7 col-lg-9 col-7 mb-4">
              <a :href="`mailto:${email}?subject=${profile.name} ${profile.surname}_I have a question`"><div class="btn button-light p-3 w-100 font3 color_white semibold clickable">Email</div></a>
            </div>
            <!-- <div class="col-xl-7 col-lg-9 col-7 mb-4">
              <button class="btn button-dark p-3 w-100 font3 color_white semibold">Call</button>
            </div> -->
          </div>
        </div>
        <!--END PAGE-->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
const waicon = () => import('@/icons/WhatsApp')

export default {
  name: 'PlannerPage',
  components: {
    waicon
  },
  data () {
    return {
      ready: false
    }
  },
  computed: {
    ...mapGetters([
      'profile',
      'travelPlanner'
    ]),
    email () {
      if (this.travelPlanner && this.travelPlanner.signature) {
        return this.travelPlanner.signature.email
      }
      return this.travelPlanner.email
    },
    imgLink () {
      if (this.travelPlanner && this.travelPlanner.signature) {
        return this.travelPlanner.signature.fullPathProfilePic
      }
      return null
    },
    name () {
      if (this.travelPlanner && this.travelPlanner.signature) {
        return this.travelPlanner.signature.name
      }
      return `${this.travelPlanner.name} ${this.travelPlanner.surname}`
    },
    whatsappLink () {
      if (this.travelPlanner.signature.mobile && this.travelPlanner.signature.mobile) {
        const num = this.travelPlanner.signature.mobile.replace(/\s/g, '').replace(/\(0\)/g, '').replace(/\+/g, '')
        return `https://wa.me/${num}`
      }
      return null
    }
  },
  async created () {
    await this.getTravelPlanner()
    this.ready = true
  },
  methods: {
    ...mapActions([
      'getTravelPlanner'
    ])
  }
}
</script>
<style scoped>
.profile_pic {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: rgba(209, 211, 212, 1);
}
.font2 {
  font-size: 30px;
  line-height: 37px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
#whatsapp_icon {
  width: 30px;
}
</style>
